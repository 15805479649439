import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import BackgroundImage from 'gatsby-background-image';
import get from 'lodash/get';
import Layout from '../components/layout';
import Person from '../components/person.js';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import styles from './field.module.css';

class FieldTemplate extends React.Component {
  render() {
    const field = get(this.props, 'data.contentfulFachgebiet');
    const { heroImage } = get(this, 'props.data.contentfulSeite');
    const defaultPortrait = get(this, 'props.data.contentfulAsset');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');

    let images;

    if (field.images) {
      images = field.images.map(({ file, internal }) => ({
        original: file.url,
        thumbnail: file.url,
        description: internal.description
      }));
    }

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <div style={{ background: '#fff' }}>
          <SEO title={field.name} />
          <BackgroundImage backgroundColor="#0e3920" className="header" fluid={heroImage.fluid}>
            <h1>{field.name}</h1>
          </BackgroundImage>
          <div className="container" style={{ paddingTop: '1rem' }}>
            {field.description &&
              <article className="markdown" dangerouslySetInnerHTML={{
                __html: field.description.childMarkdownRemark.html,
              }} />
            }
            {images &&
              <React.Fragment>
                <h2>Bildergalerie</h2>
                <ImageGallery items={images} showPlayButton={false} />
              </React.Fragment>
            }
            {field.persons &&
              <React.Fragment>
                <h2>ReferentInnen</h2>
                <div className={styles.personContainer}>
                  {field.persons.map((person, index) => (
                    <Person person={person} key={index} placeholderImage={defaultPortrait}/>
                  ))}
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default FieldTemplate;

export const pageQuery = graphql`
  query FieldBySlug($slug: String!) {
    contentfulSeite(slug: { eq: "fachgebiete" }) {
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulFachgebiet(slug: { eq: $slug }) {
      name
      description {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      images {
        file {
          url
        }
        internal {
          description
        }
      }
      persons {
        name
        department
        title
        phone
        email
        portrait {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    contentfulAsset(contentful_id: { eq: "361s49BS2XTVD7grkeQBX" }) {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
